import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  LayoutSl,
  SEO2,
  CJumbotron,
  CBreadCrumb,
  LWrap2,
  CSectTitle,
  CColorCard,
  CLabelCard,
  LRecruitUniqueSect,
  CVideoMedia,
  CVideo,
  AssetImage,
  CRecruitJumbtron,
  CTextImgCard,
  RecruitNews,
  Vertical,
  CBtnList,
  CHeroMedia,
  CHotelList,
  ContainerCrumbs
} from "../../../components/_index";
import { useMobile } from '../../../utils/use-series';
import { size } from "lodash";
import { title } from "process";
import '../../../assets/_sass/page/recruit/recruitment.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const isSp = useMobile();
  const frontmatter = data?.markdownRemark?.frontmatter;
  const crumbsData = [
    {
      title: "Top",
      src: "/recruit/",
    },
    {
      title: "採用情報",
      src: "/recruit/requirement/",
    },
  ];

  return (
    <LayoutSl isKv={false}>
      <SEO2 title={frontmatter?.title} description={frontmatter?.description} isTop={false}/>
      <section className="l_sect05 u_bgWhite l_sect04_sp recruit-font">
        <LWrap2>
          <ContainerCrumbs data={crumbsData} />
          <div className="r-requirement-head-container">
            <div className="r-requirement-head-left">
              <div className="r-requirement-head-jp">採用情報</div>
              <div className="r-requirement-head-en">Recruitment</div>
            </div>
            <div className="r-requirement-head-right">
              <div className="r-requirement-head-img-container requirement-people-pc">
                <AssetImage src="/assets/images/recruit/requirement/people.png"
                  alt=""
                  loading="lazy" />
                <p><br /></p>
              </div>
              <div className="r-requirement-head-img-container requirement-people-media">
                <AssetImage src="/assets/images/recruit/requirement/people-media.png"
                  alt=""
                  loading="lazy" />
                <p><br /></p>
              </div>
              <div className="r-requirementdata-head-article-a">
                <span className="r-requirement-head-article-title-a">ロイヤルパークホテルズは、その街ならではのおもてなし、お客様一人ひとりに合った居心地の良さを追求し、日本のおもてなしを象徴する「心地よい加減」のサービスを大切にしています。当社の一員となってともに成長していきませんか。</span>
                <p><br /></p>
              </div>
              <div className="r-requirementdata-foot mt--5-sp">
                <div className="r-requirement-foot">
                  <div className="r-requirement-foot-title">
                    新卒採用
                  </div>
                  <div className="r-requirement-foot-article">
                    2026年度新卒採用のエントリーを開始しました。
                    詳細は下記の「マイナビ2026」よりご覧ください。
                  </div>
                  <div className="r-requirement-foot-pic">
                    <a href="https://job.mynavi.jp/26/pc/search/corp98069/outline.html" target="_blank">
                    <AssetImage src="/assets/images/recruit/requirement/sign.png"
                    alt=""
                    loading="lazy" /></a>
                  </div>
                </div>
                <div className="r-requirement-foot">
                  <div className="r-requirement-foot-title">
                    キャリア採用
                  </div>
                  <div className="r-requirement-foot-article">
                    これまでの経験とスキルを活かしさらにスキルアップを目指したい方、新しい分野へステップアップしたい方など、ぜひご応募ください。
                  </div>
                  <div className="r-requirement-foot-button">
                    <a className="requirement-b_enter_black_link hover_active" href="https://hrmos.co/pages/mjhr/jobs/000001HR" target="_blank"><button className="requirement-b_enter_black">募集要項<i className="icon-blank req-icon"></i></button></a>
                  </div>
                </div>
                <p><br /></p>
              </div>
              <div className="r-requirementdata-foot">


              </div>
            </div>
          </div>

        </LWrap2>
      </section>

      <section className="u_bgGray_new recruit-font">
        <LWrap2>
          <div className="saiyo-container">
          
              <div className="r-requirement-foot-alter">

                <div className="r-requirement-foot-three">
                  <div className="r-requirement-foot-title1">
                    パート・アルバイト採用
                  </div>
                  <div className="r-requirement-foot-article-three">
                    親しみやすく活気溢れるスタッフとともに、楽しく働きながら活躍することができます。<br />
                    アルバイトを通じて、ホテルのサービスやおもてなしについて学びませんか。
                  </div>
                  <div className="r-requirement-foot-button">
                    <a className="requirement-b_enter_black_link hover_active" href="https://hrmos.co/pages/mjhr/jobs/000002HP" target="_blank"><button className="requirement-b_enter_black">募集要項<i className="icon-blank req-icon"></i></button></a>
                  </div>
                </div>

                <div className="r-requirement-foot-three">
                  <div className="r-requirement-foot-title1">
                    リファラル採用
                  </div>
                  <div className="r-requirement-foot-article-three">
                    弊社社員のご友人や知人の方で、紹介を受けられた方はご応募ください。<br />
                    社風、職場風土、仕事の進め方などを事前に紹介者に確認いただけますので、入社後のギャップも少なく、円滑に業務に取り組んでいただけます。
                  </div>
                  <div className="r-requirement-foot-button">
                    <a className="requirement-b_enter_black_link hover_active" href="https://hrmos.co/pages/mjhr/jobs/000003HP" target="_blank"><button className="requirement-b_enter_black">募集要項<i className="icon-blank req-icon"></i></button></a>
                  </div>
                </div>
             
                <div className="r-requirement-foot-three">
                  <div className="r-requirement-foot-title1">
                    アルムナイ採用
                  </div>
                  <div className="r-requirement-foot-article-three">
                    弊社を一度、卒業された方に向け、再度活躍いただける場を提供していきたいと考えております。<br />
                    もう一度、弊社で挑戦したい。そんな志をお持ちの皆様からのご応募をお待ちしております。
                  </div>
                  <div className="r-requirement-foot-button">
                    <a className="requirement-b_enter_black_link hover_active" href="https://hrmos.co/pages/mjhr/jobs/000004HP" target="_blank"><button className="requirement-b_enter_black">募集要項<i className="icon-blank req-icon"></i></button></a>
                  </div>
                </div>

              </div>
          </div>
        </LWrap2>
      </section>
    </LayoutSl>

  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
